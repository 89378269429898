import React, { useState } from 'react';

// Collapsible Section Component (same as in AssessmentForm)
const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4 border rounded-lg">
      <button
        onClick={toggleSection}
        className="w-full text-left px-4 py-2 bg-gray-200 font-bold text-lg rounded-lg"
      >
        {title}
        <span className="float-right">{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && (
        <div className="px-4 py-2 bg-white">
          {children}
        </div>
      )}
    </div>
  );
};

// Collapsible Subpart Component for subparts (mirroring AssessmentForm)
const CollapsibleSubpart = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubpart = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="ml-4 mb-4 border-l-2 pl-4">
      <button
        onClick={toggleSubpart}
        className="w-full text-left py-2 text-lg font-medium"
      >
        {title}
        <span className="float-right">{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && (
        <div className="pl-4 bg-gray-50 rounded-md">
          {children}
        </div>
      )}
    </div>
  );
};

const HLCForm = () => {
  const [formData, setFormData] = useState({
    sleepQuality: '',
    stressLevel: '',
    dietQuality: '',
    exerciseFrequency: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('HLC Form submitted:', formData);
    // Here you would typically send the data to a server
  };

  return (
    <div className="max-w-2xl mx-auto p-4 mb-16">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold mb-6 text-center">HLC Assessment Form</h2>

        {/* Level 1: Nutrition and Lifestyle */}
        <CollapsibleSection title="Level 1: Nutrition and Lifestyle">

          {/* Subpart: You are what you eat */}
          <CollapsibleSubpart title="You are what you eat">
            <p>This section deals with your eating habits, dietary preferences, and how food affects your health.</p>
          </CollapsibleSubpart>

          {/* Subpart: Stress */}
          <CollapsibleSubpart title="Stress">
            <p>This section covers how stress impacts your daily life and health.</p>

            {/* Form Elements for Stress Level */}
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Stress Level:
              </label>
              <div className="mt-2">
                {['Low', 'Moderate', 'High', 'Very High'].map((option) => (
                  <label key={option} className="inline-flex items-center mr-6">
                    <input
                      type="radio"
                      className="form-radio"
                      name="stressLevel"
                      value={option}
                      checked={formData.stressLevel === option}
                      onChange={handleChange}
                    />
                    <span className="ml-2">{option}</span>
                  </label>
                ))}
              </div>
            </div>
          </CollapsibleSubpart>

          {/* Subpart: Circadian Health */}
          <CollapsibleSubpart title="Circadian Health">
            <p>This section focuses on your sleep patterns and how they affect your overall well-being.</p>

            {/* Form Elements for Sleep Quality */}
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Sleep Quality:
              </label>
              <div className="mt-2">
                {['Poor', 'Fair', 'Good', 'Excellent'].map((option) => (
                  <label key={option} className="inline-flex items-center mr-6">
                    <input
                      type="radio"
                      className="form-radio"
                      name="sleepQuality"
                      value={option}
                      checked={formData.sleepQuality === option}
                      onChange={handleChange}
                    />
                    <span className="ml-2">{option}</span>
                  </label>
                ))}
              </div>
            </div>
          </CollapsibleSubpart>

          {/* Subpart: You are when you eat */}
          <CollapsibleSubpart title="You are when you eat">
            <p>This section evaluates the timing of your meals and its effect on your health.</p>
          </CollapsibleSubpart>

          {/* Subpart: Digestive System Health */}
          <CollapsibleSubpart title="Digestive System Health">
            <p>This section assesses the health of your digestive system.</p>
          </CollapsibleSubpart>

          {/* Subpart: Fungus & Parasites */}
          <CollapsibleSubpart title="Fungus & Parasites">
            <p>This section evaluates if fungal infections or parasites could be impacting your health.</p>
          </CollapsibleSubpart>

          {/* Subpart: Detoxification System Health */}
          <CollapsibleSubpart title="Detoxification System Health">
            <p>This section focuses on your body's ability to detoxify and maintain optimal health.</p>
          </CollapsibleSubpart>

        </CollapsibleSection>

        {/* Level 2 and Level 3 Sections */}
        <CollapsibleSection title="Level 2: Health Appraisal Questionnaire">
          {/* Add subparts and content for Level 2 here */}
        </CollapsibleSection>

        <CollapsibleSection title="Level 3: Level 3">
          {/* Add subparts and content for Level 3 here */}
        </CollapsibleSection>

        {/* Submit button for the entire form */}
        <div className="flex items-center justify-center mt-6">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="submit"
          >
            Submit HLC Assessment
          </button>
        </div>
      </form>
    </div>
  );
};

export default HLCForm;