import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <ul className="flex space-x-4 justify-center">
        <li><Link to="/" className="text-white hover:text-gray-300">Home</Link></li>
        <li><Link to="/about" className="text-white hover:text-gray-300">About</Link></li>
        <li><Link to="/chek-assessment" className="text-white hover:text-gray-300">CHEK Assessment</Link></li>
        <li><Link to="/hlc" className="text-white hover:text-gray-300">HLC</Link></li>
	<li><Link to="/primal-diet" className="text-white hover:text-gray-300">Primal Diet Assessment</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;
