import React from 'react';

const Home = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Welcome to Our Health Assessment Platform</h1>
      <p className="mb-4">This platform provides various health assessments to help you understand and improve your overall well-being.</p>
      <p>Please use the navigation menu to explore our services and take assessments.</p>
    </div>
  );
};

export default Home;
