import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="mb-4">We are dedicated to providing comprehensive health assessments to help individuals achieve their optimal health and wellness goals.</p>
      <p className="mb-4">Our platform offers various assessment tools, including the CHEK Assessment and HLC (Holistic Lifestyle Coaching) evaluation.</p>
      <p>Our team of experts is committed to supporting you on your journey to better health and well-being.</p>
    </div>
  );
};

export default About;
