import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navigation from './Navigation';
import Home from './Home';
import About from './About';
import AssessmentForm from './AssessmentForm';
import HLCForm from './HLCForm';
import PrimalDiet from './PrimalDiet';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/chek-assessment" element={<AssessmentForm />} />
        <Route path="/hlc" element={<HLCForm />} />
        <Route path="/primal-diet" element={<PrimalDiet />} />
      </Routes>
    </div>
  );
}

export default App;
