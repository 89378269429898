import React, { useState } from 'react';

// Collapsible Section Component
const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4 border rounded-lg">
      <button
        onClick={toggleSection}
        className="w-full text-left px-4 py-2 bg-gray-200 font-bold text-lg rounded-lg"
      >
        {title}
        <span className="float-right">{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && (
        <div className="px-4 py-2 bg-white">
          {children}
        </div>
      )}
    </div>
  );
};

// Main Assessment Form Component
const AssessmentForm = () => {
  const [formData, setFormData] = useState({
    clientName: '',
    dateOfBirth: '',
    dateOfAssessment: '',
    mobileNumber: '',
    email: '',
    commitmentScore: '',
    dreamsGoals: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div className="max-w-2xl mx-auto p-4 mb-16">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold mb-6 text-center">CHEK Assessment Form</h2>
        
        {/* Section 1: Personal Information */}
        <CollapsibleSection title="Personal Information">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="clientName">
              Client Name:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="clientName"
              type="text"
              placeholder="Enter client name"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfBirth">
                Date of Birth:
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dateOfBirth"
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
              />
            </div>
            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfAssessment">
                Date of Assessment:
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dateOfAssessment"
                type="date"
                name="dateOfAssessment"
                value={formData.dateOfAssessment}
                onChange={handleChange}
              />
            </div>
            <div className="w-full md:w-1/3 px-2">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobileNumber">
                Mobile Number:
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="mobileNumber"
                type="tel"
                placeholder="Enter mobile number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            </div>
          </div>
        </CollapsibleSection>

        {/* Section 2: Contact Information */}
        <CollapsibleSection title="Contact Information">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </CollapsibleSection>

        {/* Section 3: Assessment Details */}
        <CollapsibleSection title="Assessment Details">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="commitmentScore">
              Commitment (Score /10):
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="commitmentScore"
              type="number"
              min="0"
              max="10"
              placeholder="Enter score"
              name="commitmentScore"
              value={formData.commitmentScore}
              onChange={handleChange}
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dreamsGoals">
              Dreams/Goals/Objectives:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dreamsGoals"
              placeholder="Describe dreams or goals"
              rows="4"
              name="dreamsGoals"
              value={formData.dreamsGoals}
              onChange={handleChange}
            ></textarea>
          </div>
        </CollapsibleSection>

        <div className="flex items-center justify-center">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="submit"
          >
            Submit Assessment
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssessmentForm;