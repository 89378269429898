import React, { useState, useRef, useEffect } from 'react';

// New component for detailed result information
const DetailedResultInfo = ({ type }) => {
  const infoContent = {
    Variable: (
      <div>
        <h3 className="text-xl font-semibold mb-2">Variable Type (Mixed Protein-Carb Type)</h3>
        <p>As a Variable Type, you have a balanced metabolism that can handle a mix of proteins and carbohydrates well. Here are some key points:</p>
        <ul className="list-disc list-inside mt-2">
          <li>Your diet should include a balanced mix of proteins, fats, and carbohydrates.</li>
          <li>You may benefit from alternating between higher protein and higher carb meals.</li>
          <li>Listen to your body's hunger signals, as they may vary day to day.</li>
          <li>Focus on whole, unprocessed foods for optimal health.</li>
        </ul>
      </div>
    ),
    Polar: (
      <div>
        <h3 className="text-xl font-semibold mb-2">Polar Type (Protein Type)</h3>
        <p>As a Polar Type, your body tends to function best with a higher intake of proteins and fats. Here are some key points:</p>
        <ul className="list-disc list-inside mt-2">
          <li>Focus on high-quality protein sources like meat, fish, and eggs.</li>
          <li>Include healthy fats in your diet, such as avocados, nuts, and olive oil.</li>
          <li>Limit your intake of grains and sugary foods.</li>
          <li>Eat more frequently if needed to maintain stable energy levels.</li>
        </ul>
      </div>
    ),
    Equatorial: (
      <div>
        <h3 className="text-xl font-semibold mb-2">Equatorial Type (Carbohydrate Type)</h3>
        <p>As an Equatorial Type, your body tends to function best with a higher intake of carbohydrates. Here are some key points:</p>
        <ul className="list-disc list-inside mt-2">
          <li>Focus on complex carbohydrates like whole grains, fruits, and vegetables.</li>
          <li>Include lean proteins in your diet, but in smaller quantities.</li>
          <li>Be mindful of your fat intake, focusing on healthy sources.</li>
          <li>You may feel best with a higher frequency of meals throughout the day.</li>
        </ul>
      </div>
    )
  };

  return (
    <div className="mt-6 p-4 bg-blue-50 rounded-lg">
      {infoContent[type]}
    </div>
  );
};

const PrimalDietAssessment = () => {
  const questions = [
    {
      id: 1,
      question: "I sleep best:",
      options: {
        a: "when I eat 1-2 hours before going to sleep.",
        b: "when I eat as much as 3 or 4 hours before going to sleep."
      }
    },
    {
      id: 2,
      question: "I sleep best if:",
      options: {
        a: "my dinner is composed of mainly meat with some vegetables or other carbohydrates.",
        b: "my dinner is composed mainly of vegetables or other carbohydrates and a comparatively small serving of meat."
      }
    },
    {
      id: 3,
      question: "I sleep best and wake up feeling most rested if I:",
      options: {
        a: "don't eat sweet desserts like cakes, candy or cookies. If I eat a rich dessert that is not overly sweet, such as high quality full fat ice cream, I tend to sleep okay.",
        b: "even if I should eat a sweet dessert now and then."
      }
    },
    {
      id: 4,
      question: "After vigorous exercise, I tend to crave:",
      options: {
        a: "foods or drinks with higher protein and/or fat content such as a bodybuilder's high-protein shake.",
	b: "foods or drinks higher in carbohydrate (sweeter), such as Gatorade, soda, or fruit juice.",
       }
    },
    {
      id: 5,
      question: "In order to last 4 hours between meals and maintain mental clarity and a sense of well-being, I prefer to eat:",
      options: {
        a: "a meal predominantly meat based, high in protein and fat (such as roast beef, pork, salmon...) with carbohydrate as a supplement to the meal.",
        b: "a meal predominantyl carbohydrate based, such as a salad or vegetables with some bread, and a small amount of protein.",
       }
    },
    {
      id: 6,
      question: "Which best describes your reaction to sugar or sweet foods such as jelly donuts, candy or sweetened drinks:",
      options: {
        a: "I get a rush of energy, may get the jitters or may feed good for a short time but then I am likely to have a blood sugar crash, resulting in the need for more of the same or having to eat some real food to normalize myself.",
        b: "I can do quite well on sweet things and I don't seem to be negatively affected, even though I know that too much is not good for me.",
       }
    },
    {
      id: 7,
      question: "My body shape is closest to:",
      options: {
        a: "Mesomorphic or 'V' shaped, like a typical wrestler, gymnast or weight lifter type or Endomorphic or more naturally round shaped but I am naturally quite strong and respond very well to anaerobic sprots or strength training type exercises.",
        b: "Ectomorphic or long and lean like a rower or a triathelete or Endomorphic or more naturally round shaped but I respond better to enduracne athletics than to strength training or anaerobic sprots.",
       }
      },
      {
       id: 8,
       question: "Which statement best descrbes your disposition toward food in general:",
       options: {
        a: "I love food and live to eat!",
        b: "I am not fussed over food in general and I can eat to live in general",
       }
      },
      {
       id: 9,
       question: "In general, I prefer:",
       options: {
        a: "To salt my foods most of the time.",
        b: "To taste my goods and apply salt once in a while, but am not particularly attracted to salty foods.",
       }
      },
      {
       id: 10,
       question: "Instinctually, I prefer to eat:",
       options: {
        a: "Dark meat, such as teh chicken or turkey legs and thighs over the white breast meat.",
        b: "Light meat such aas the chicken or turkey breast over teh dark leg and thigh meat.",
       }
      },
      {
       id: 11,
       question: "Which list of fish most appeals to your taste without concern for calories or fat content:",
       options: {
        a: "Anchovy, caviar, herring, mussels, sardines, abalone, clams, crab, crayfish, lobster, mackerel, octopus, oyster, salmon, scallops, shrimp, snail, squid, tuna (dark meat)",
        b: "Light fish, catfish, cod, flounder, haddock, perch, scrod, sole, trout, tuna (white), turbot",
       }
      },
      {
      id: 12,
       question: "When eating dairy products, do you fee best after eating:",
       options: {
        a: "Richer full fat yougurts and cheeses or desserts.",
        b: "Lighter low fat yougurts and cheeses or desserts.",
       }
      },
     {
      id: 13,
      question: "With regard to snacking,do you:",
      options: {
        a: "Tend to do better with snacks between meals.",
        b: "Tend to last between meals easily in general..",
       }
      },
     {
      id: 14,
      question: "What characteristics best describe you:",
      options: {
        a: "Creative, digest food well in general, have a strong immune system and don't get sick often, have an appeite for proteins, feel good when cating fats or fatty foods, more muscular or inclined to gain muscle and/or strength easily.",
        b: "Logical, more lithe of build, tend to be sensitive to temperature changes and flu season and wouldn't really consider your immune system one of your stronger attributes, prefer light meats and lower fat foods, are more inclined toward endurance athletics.",
       }
      },
  ];

  const [answers, setAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [storedResults, setStoredResults] = useState(null);
  const resultsRef = useRef(null);

  useEffect(() => {
    if (showResults && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showResults]);

  useEffect(() => {
    const savedResults = localStorage.getItem('dietQuizResults');
    if (savedResults) {
      const results = JSON.parse(savedResults);
      setStoredResults(results);
      setShowResults(true);
    }
  }, []);

  const handleAddToReport = () => {
    console.log("Adding results to report...");
  };

  const handleReset = () => {
    setAnswers({});
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton) => (radioButton.checked = false));
    setShowResults(false);
    localStorage.removeItem('dietQuizResults');
    setStoredResults(null);
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
  };

  const handleChange = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value
    }));
  };

const calculateResults = () => {
    const totalA = Object.values(answers).filter((answer) => answer === 'a').length;
    const totalB = Object.values(answers).filter((answer) => answer === 'b').length;
    return { totalA, totalB };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const results = calculateResults();
    const resultType = getResultType(results.totalA, results.totalB);

    const resultData = {
      totalA: results.totalA,
      totalB: results.totalB,
      resultType: resultType
    };

    localStorage.setItem('dietQuizResults', JSON.stringify(resultData));
    setStoredResults(resultData);
    setShowResults(true);
  };

  const getResultType = () => {
    const results = calculateResults();
    if (Math.abs(results.totalA - results.totalB) <= 2) {
      return "Variable";
    } else if (results.totalA > results.totalB) {
      return "Polar";
    } else {
      return "Equatorial";
    }
  };

  return (
    <div className="container mx-auto p-4 mb-16">
      <h1 className="text-3xl font-bold mb-6">Primal Diet Assessment</h1>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {questions.map((q) => (
          <div key={q.id} className="mb-6">
            <p className="font-semibold mb-2">{q.id}. {q.question}</p>
            <div className="ml-4">
              {['a', 'b'].map((option) => (
                <label key={option} className="inline-flex items-center mb-2">
                  <input
                    type="radio"
                    className="form-radio"
                    name={`question-${q.id}`}
                    value={option}
                    onChange={() => handleChange(q.id, option)}
                    required
                  />
                  <span className="ml-2">{option.toUpperCase()}. {q.options[option]}</span>
                </label>
              ))}
            </div>
          </div>
        ))}

        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit Assessment
          </button>
        </div>
      </form>

      {showResults && storedResults && (
        <div ref={resultsRef} className="mt-8 p-6 bg-white shadow-md rounded-lg mb-16">
          <h2 className="text-2xl font-bold mb-4">Assessment Results</h2>
          <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-6">
            <div className="flex-grow">
              <p>Total A responses: {storedResults.totalA}</p>
              <p>Total B responses: {storedResults.totalB}</p>
              <p className="mt-2 font-semibold">You are a {storedResults.resultType} Type.</p>
            </div>
            <div className="flex-shrink-0">
              <img
                src={`/images/${storedResults.resultType}.jpg`}
                alt="Assessment Result"
                className="w-32 h-32 object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
          <DetailedResultInfo type={storedResults.resultType} />

          {/* Flexbox for buttons */}
          <div className="mt-4 flex justify-between">
            <button
              type="button"
              onClick={handleAddToReport}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add to Report
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Reset Assessment
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrimalDietAssessment;